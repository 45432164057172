// import logo from './logo.svg';
import { useState } from 'react';
import './App.css';
import Circles from './Circles';

const width = window.screen.width;
const height = window.screen.height;

const App2 = () => {
  
  return (
    <>
    <div className="scrim"></div>
    <Circles width={width} height={height} />
    </>
  );
}

export default App2;
