import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { scaleLinear, select } from "d3"; 

import { getData } from "./utils";

let colors = ['#2176ae', '#57b8ff', '#b66d0d', '#fbb13c', '#fe6847'];

const Circles = ({ width, height }) => {
const [data, setData] = useState(getData());

useEffect(() => {
    setTimeout(() => {setData(getData())}, 10000);
}, [data]);

  const svgRef = useRef();
  useEffect(() => {
    const maxRadius = 80;
    const xScale = scaleLinear().domain([0, 1]).range([0, width]);
    const yScale = scaleLinear().domain([0, 1]).range([height, 0]);
    const rScale = scaleLinear().domain([0, 1]).range([0, maxRadius]);
    select(svgRef.current)
    .selectAll('circle')
    .data(data)
    .transition()
    .duration(10000)
    .attr('cx', d => xScale(d.x))
    .attr('cy', d => yScale(d.y))
    .attr('r', d => rScale(d.r))
    .attr('fill', d => colors[d.color])
    .attr('opacity', .80);
  }, [data, width, height]);

// const refreshData = () => {
//     setInterval(() => {setData(getData())}, 1000);
//     // setData(getData());
// };

// useEffect(() => {
//     refreshData();
// }, []);

  return (
    <div>
      <svg ref={svgRef} data={data} viewBox={`0 0 ${width} ${height}`}>
        {data.map(d => <circle fill='#fff'></circle>)}
      </svg>
      {/* <div className="buttonDiv">
        <button class="refreshButton" onClick={refreshData}>Refresh Data</button>
      </div> */}
    </div>
  );
};

export default Circles;
